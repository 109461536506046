import React from "react";
import Chart from "react-apexcharts";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Jan",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Feb",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Mar",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Jun",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Jul",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Aug",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Sep",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Oct",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Nov",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Dec",
    Expenses: 4000,
    Income: 2000,
  },
];

function TransactionChart() {
  return (
    <div className="h-[22rem] bg-white p-4 rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] flex flex-col flex-1">
      <strong className="text-gray-700 font-medium">Transaction</strong>
      <div className="w-full mt-3 flex-1 text-xs">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 10,
              left: -10,
              bottom: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3 0 0" vertical={false} />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Income" fill="#0ea5e9" />
            <Bar dataKey="Expenses" fill="#ea580c" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default TransactionChart;
