// FormInput.js
import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const FormInput = ({
  label,
  name,
  error,
  helperText,
  type,
  inputPrice,
  disabled,
  ...props
}) => (
  <TextField
    margin="normal"
    required
    fullWidth
    id={name}
    label={label}
    name={name}
    disabled={disabled}
    type={type}
    autoComplete={name === "email" ? "email" : "current-password"}
    autoFocus={name === "username"}
    error={error}
    helperText={helperText}
    InputProps={{
      startAdornment: inputPrice && (
        <InputAdornment position="start">Rp.</InputAdornment>
      ),
    }}
    {...props}
  />
);

export default FormInput;
