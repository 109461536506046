import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
  Modal,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import { tableHeadTransaction } from "../../utils/Constants";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SearchIcon from "@mui/icons-material/Search";
import { exportToXLSX } from "../../helpers/functions/generateExcel";
import moment from "moment";
import ModalDetail from "../../components/ModalDetail";

function Transaction() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  // ==================
  const [salesList, setSalesList] = useState(null);
  // ==================
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  // ==================
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [transactionCode, setTransactionType] = useState(20);
  // ==================
  const [transactionSelected, setTransactionSelected] = useState([]);
  const [detailTransaction, setDetailTransaction] = useState([]);
  const [isVisibleDetail, setIsVisibleDetail] = useState(false);

  // ===============
  const onGetSales = async () => {
    setIsLoading(true);
    const currentPage = page + 1;
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    try {
      const res = await api
        .getTransactionData(
          branchCodeSelected,
          transactionCode,
          dateFromSelected,
          dateToSelected,
          rowsPerPage,
          currentPage
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setTotalRecord(res.data.totalData);
            setSalesList(res.data.data);
            setIsLoading(false);
          } else {
            setSalesList([]);
            onError(res.message);
          }
        })
        .catch((error) => {
          setSalesList([]);
          onError(error.message);
        });
    } catch (error) {
      setSalesList([]);
      onError(error.message);
    }
  };

  const onGetBranches = async () => {
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const newBranch = {
              id: "",
              branchName: "All Branches",
              address: "",
              branchPhone: "",
              statusName: "",
              statusCode: "",
              branchCode: "",
              createdDate: "",
              updatedDate: "",
            };
            const updatedBranchList = [newBranch, ...res.data.data];
            setBranchList(updatedBranchList);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetDetailTransaction = async (data) => {
    try {
      const res = await api
        .getDetailTransaction(data.id)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setDetailTransaction(res.data);
            setTransactionSelected(data);
            setIsVisibleDetail(true);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onHandleReport = async () => {
    setIsSpinner(true);
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    try {
      const res = await api
        .reportDownload(transactionCode, dateFromSelected, dateToSelected)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const fileName = "sales-report";
            setIsSpinner(false);
            exportToXLSX(res.data.data, fileName);
            onSuccessSubmit();
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  // ==============
  useEffect(() => {
    const fetch = async () => {
      await onGetSales();
    };
    fetch();
  }, [page]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  const onError = async (message) => {
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSuccessSubmit = async () => {
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
  };

  const handlePreviewDetail = (itemSelected, indexSelected) => {
    onGetDetailTransaction(itemSelected);
  };

  // ===============
  const breadcrumbs = [
    <Typography key="3" color="text.primary" fontSize={12}>
      Sales
    </Typography>,
  ];

  return (
    <div className="max-w-[94%] flex flex-col m-auto">
      <Spinner isShowSpinner={isSpinner} />
      <div className="flex w-full h-16 mt-6 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
        <div className="flex flex-row w-full px-6 justify-between items-center">
          <div className="">
            <h3 className="text-xl font-semibold">Sales</h3>
          </div>
          <div className="">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Sales List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<DownloadForOfflineIcon />}
            onClick={() => {
              onHandleReport();
            }}
          >
            Download Report
          </Button>
        </div>

        {/* search */}
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <PickerSearch
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchCodeSelected(v.branchCode);
                } else {
                  setBranchCodeSelected("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Outlet"}
              placeholderPicker={"search outlet"}
              sizePicker={"small"}
              isVisibleLabelPicker={true}
              labelTitlePicker={"CHOOSE OUTLET"}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <h4 className="font-semibold mb-2 text-xs">DATE FROM</h4>
            <FormDatePicker
              labelFormDatePicker="Date From"
              dateValue={selectedDateFrom}
              onChangeDate={(val) => setSelectedDateFrom(val)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <h4 className="font-semibold mb-2 text-xs">DATE TO</h4>
            <FormDatePicker
              labelFormDatePicker="Date To"
              dateValue={selectedDateTo}
              onChangeDate={(val) => setSelectedDateTo(val)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              component="label"
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                onGetSales();
              }}
              sx={{ marginTop: 3 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* table list */}
        <TableList
          tableStructure="sales"
          tableName={"Sales"}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadTransaction}
          data={salesList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={handlePreviewDetail}
        />
      </div>
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
      <ModalDetail
        isAmount={true}
        onClose={() => setIsVisibleDetail(false)}
        isVisibleDetail={isVisibleDetail}
        transactionSelected={transactionSelected}
        detailTransaction={detailTransaction}
      />
    </div>
  );
}

export default Transaction;
