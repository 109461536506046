import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import { tableHeadProductProvit } from "../../utils/Constants";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SearchIcon from "@mui/icons-material/Search";
import { exportToXLSX } from "../../helpers/functions/generateExcel";
import moment from "moment";

function ProductProvit() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  
  // State for product profit data
  const [productProvitList, setProductProvitList] = useState(null);
  
  // State for branch data and filters
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  
  // State for date filters
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [transactionCode, setTransactionType] = useState("");

  // Fetch product profit data
  const onGetProductProvit = async () => {
    setIsLoading(true);
    const currentPage = page + 1;
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    try {
      const res = await api.getProductProvit(
        searchBranchName,
        dateFromSelected,
        dateToSelected,
        rowsPerPage,
        currentPage
      );
      if (res.statusCode === 200) {
        setTotalRecord(res.data.totalData);
        setProductProvitList(res.data.data);
      } else {
        setProductProvitList([]);
        onError(res.message);
      }
    } catch (error) {
      setProductProvitList([]);
      onError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch branch data
  const onGetBranches = async () => {
    try {
      const res = await api.getBranch(searchBranchName);
      if (res.statusCode === 200) {
        const newBranch = {
          id: "",
          branchName: "All Branches",
          address: "",
          branchPhone: "",
          statusName: "",
          statusCode: "",
          branchCode: "",
          createdDate: "",
          updatedDate: "",
        };
        setBranchList([newBranch, ...res.data.data]);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error.message);
    }
  };

  // Download report
  const onHandleReport = async () => {
    setIsSpinner(true);
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    try {
      const res = await api.reportDownload(transactionCode, dateFromSelected, dateToSelected);
      if (res.statusCode === 200) {
        exportToXLSX(res.data.data, "product-profit-report");
        onSuccessSubmit();
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error.message);
    } finally {
      setIsSpinner(false);
    }
  };

  useEffect(() => {
    onGetProductProvit();
  }, [page]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  const onError = (message) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSuccessSubmit = () => {
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
  };

  const breadcrumbs = [
    <Typography key="3" color="text.primary" fontSize={12}>
      Product Profit
    </Typography>,
  ];

  return (
    <div className="max-w-[94%] flex flex-col m-auto">
      <div className="flex w-full h-16 mt-6 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
        <div className="flex flex-row w-full px-6 justify-between items-center">
          <h3 className="text-xl font-semibold">Product Profit</h3>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </div>
      </div>
      <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Product Profit List</h3>
          <Button
            variant="outlined"
            startIcon={<DownloadForOfflineIcon />}
            onClick={onHandleReport}
          >
            Download Report
          </Button>
        </div>

        {/* Search Filters */}
        <Grid container spacing={1} alignItems="center" sx={{ marginBottom: 1, marginTop: 1, marginLeft: 2, maxWidth: "96%" }}>
          <Grid item xs={12} sm={3}>
            <PickerSearch
              dataList={branchList}
              onInputChange={(event, newInputValue) => setSearchBranchName(newInputValue)}
              onChange={(e, v) => setBranchCodeSelected(v ? v.branchCode : "")}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker="Choose Outlet"
              placeholderPicker="search outlet"
              sizePicker="small"
              isVisibleLabelPicker={true}
              labelTitlePicker="CHOOSE OUTLET"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <h4 className="font-semibold mb-2 text-xs">DATE FROM</h4>
            <FormDatePicker
              labelFormDatePicker="Date From"
              dateValue={selectedDateFrom}
              onChangeDate={setSelectedDateFrom}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <h4 className="font-semibold mb-2 text-xs">DATE TO</h4>
            <FormDatePicker
              labelFormDatePicker="Date To"
              dateValue={selectedDateTo}
              onChangeDate={setSelectedDateTo}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={onGetProductProvit}
              sx={{ marginTop: 3 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* Table List */}
        <TableList
          tableStructure="best-seller-products"
          tableName="Product Profit"
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadProductProvit} // Use the correct table header here
          data={productProvitList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={() => alert("detail")}
        />
      </div>
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
      <Spinner isShowSpinner={isSpinner} />
    </div>
  );
}

export default ProductProvit;
