import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
  Modal,
  FormInput,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import {
  validateFormInputAddOn,
  validateFormInputSettingAddon,
} from "../../helpers/functions/validateTextInput";
import api from "../../helpers/api";
import { tableHeadAddOn } from "../../utils/Constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";

function AddOns() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isReload, setIsReload] = useState(false);
  // ==================
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("");
  // ==================
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // ==================
  const [addOnList, setAddOnList] = useState([]);
  const [searchAddonName, setSearchAddonName] = useState("");
  // ==================
  const [addOnName, setAddOnName] = useState("");
  const [addOnPrice, setAddOnPrice] = useState("");
  const [addOnType, setAddOnType] = useState("");
  const [addOnTypeCode, setAddOnTypeCode] = useState(0);
  // ==========
  const [isSettingAddOn, setIsSettingAddOn] = useState(false);
  const [addOnSelected, setAddOnSelected] = useState([]);
  const [branchCodeAddOn, setBranchCodeAddOn] = useState("");
  const [productCodeAddOn, setProductCodeAddOn] = useState("");
  // ==========
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchNameSelected, setBranchNameSelected] = useState([]);
  // ==========
  const [productList, setProductList] = useState([]);
  const [productNameSelected, setProductNameSelected] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  // ==========

  const onGetAddOns = async () => {
    setIsLoading(true);
    try {
      const res = await api
        .getAddOns()
        .then(async (res) => {
          if (res.statusCode === 200) {
            setAddOnList(res.data.data);
            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetBranches = async () => {
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const newBranch = {
              id: "",
              branchName: "All Branches",
              address: "",
              branchPhone: "",
              statusName: "",
              statusCode: "",
              branchCode: "ALL",
              createdDate: "",
              updatedDate: "",
            };
            const updatedBranchList = [newBranch, ...res.data.data];
            setBranchList(updatedBranchList);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetProducts = async () => {
    try {
      const res = await api
        .getProduct(
          searchProductName,
          branchCodeAddOn === "ALL" ? "" : branchCodeAddOn
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            const newProduct = {
              id: "",
              brandId: "",
              categoryId: "",
              productName: "All Products",
              categoryName: "",
              productCode: "ALL",
              brandName: "",
              productSku: "",
              imageUrl: "",
              note: "",
              createdDate: "",
              updatedDate: "",
            };
            const updatedProductList = [newProduct, ...res.data.data];
            setProductList(updatedProductList);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onSaveAddOn = async () => {
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .saveAddOn(addOnName, addOnPrice, addOnType, addOnTypeCode)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  const onSaveSettingAddOn = async () => {
    // const newProductAttachment = [productNameSelected];
    // const a = {
    //   branchCode: branchCodeAddOn,
    //   productCode: productCodeAddOn,
    //   addonsId: addOnSelected.id,
    //   attachments: [productNameSelected],
    // };
    // console.log("setting addon", a);
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .saveSettingAddOn(
          branchCodeAddOn,
          productCodeAddOn,
          addOnSelected.id,
          productCodeAddOn === "ALL" ? [] : [productNameSelected]
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsSettingAddOn(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsSettingAddOn(true);
          onError(error.message);
        });
    } catch (error) {
      setIsSettingAddOn(true);
      onError(error.message);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await onGetAddOns();
    };
    fetch();
  }, []);

  useEffect(() => {
    if (isReload) {
      onGetAddOns();
    }
  }, [isReload]);

  useEffect(() => {
    onGetProducts();
  }, [searchProductName, branchCodeAddOn]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  const onSuccessSubmit = async () => {
    setAddOnName("");
    setAddOnPrice("");
    setAddOnType("");
    setSearchProductName("");
    setBranchCodeAddOn("");
    setProductCodeAddOn("");
    setProductNameSelected([]);
    setSearchBranchName("");
    setBranchNameSelected([]);
    setIsLoading(false);
    setIsOpenModal(false);
    setIsSettingAddOn(false);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setErrors({});
    setAddOnName("");
    setAddOnPrice("");
    setAddOnType("");
    setSearchProductName("");
    setBranchCodeAddOn("");
    setProductCodeAddOn("");
    setProductNameSelected([]);
    setSearchBranchName("");
    setBranchNameSelected([]);
    setIsOpenModal(false);
    setIsSettingAddOn(false);
  };

  const onError = async (message) => {
    setIsReload(false);
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const handleChangePage = (event, newPage) => {
    alert("next");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    alert("page select");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!addOnName || addOnName.trim() === "") {
      newErrors.addOnName = "Adds on name is required";
    }

    if (!addOnPrice || addOnPrice.trim() === "") {
      newErrors.addOnPrice = "Adds on price is required";
    }

    if (!addOnType || addOnType.trim() === "") {
      newErrors.addOnType = "Adds on type is required";
    }

    return newErrors;
  };

  const validateFormSettingAddon = () => {
    const newErrors = {};

    if (
      !branchNameSelected ||
      branchNameSelected === null ||
      branchNameSelected.length === 0
    ) {
      newErrors.branchNameSelected = "Branch is required to choose";
    }

    if (
      !productNameSelected ||
      productNameSelected === null ||
      productNameSelected.length === 0
    ) {
      newErrors.productNameSelected = "Product is required to choose";
    }

    return newErrors;
  };

  const handleSubmit = async () => {
    if (mode === "add") {
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
      await validateFormInputAddOn(addOnName, addOnPrice, addOnType);
      await onSaveAddOn();
    } else {
      const validationErrors = validateFormSettingAddon();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
      await validateFormInputSettingAddon(
        branchNameSelected,
        productNameSelected
      );
      await onSaveSettingAddOn();
    }
  };

  const onSettingAddOn = async (addOnItem) => {
    setMode("setting");
    setAddOnSelected(addOnItem);
    setIsSettingAddOn(true);
  };

  // ===============
  const breadcrumbs = [
    <Typography key="3" color="text.primary" fontSize={12}>
      Adds On
    </Typography>,
  ];

  return (
    <div className="max-w-[94%] flex flex-col m-auto">
      <Spinner isShowSpinner={isSpinner} />
      <div className="flex w-full h-16 mt-6 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
        <div className="flex flex-row w-full px-6 justify-between items-center">
          <div className="">
            <h3 className="text-xl font-semibold">Add On List</h3>
          </div>
          <div className="">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Add On List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setMode("add");
              setIsOpenModal(true);
            }}
          >
            Create New Adds On
          </Button>
        </div>

        {/* table list */}
        <TableList
          tableStructure="addon-list"
          tableName={"Product List"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadAddOn}
          data={addOnList}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onEditData={onSettingAddOn}
        />
      </div>

      <Modal
        modalTitle={"Create New Adds On"}
        fullScreen={false}
        isVisible={isOpenModal}
        buttonSaveTitle={"Save Adds On"}
        onPressCancel={() => {
          onCloseModal();
        }}
        onPressSave={() => handleSubmit()}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <FormInput
              label="AddOn Name"
              name="addOnName"
              error={!!errors.addOnName}
              helperText={errors.addOnName}
              value={addOnName}
              onChange={(event) => {
                setAddOnName(event.target.value);
                setErrors({});
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              label="AddOn Price"
              name="addOnPrice"
              type="number"
              inputPrice={true}
              error={!!errors.addOnPrice}
              helperText={errors.addOnPrice}
              value={addOnPrice}
              onChange={(event) => {
                setAddOnPrice(event.target.value);
                setErrors({});
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              label="AddOn Type"
              name="addOnType"
              error={!!errors.addOnType}
              helperText={errors.addOnType}
              value={addOnType}
              onChange={(event) => {
                setAddOnType(event.target.value);
                setErrors({});
              }}
            />
          </Grid>
        </Grid>
      </Modal>

      <Modal
        modalTitle="Setting AddOn"
        isVisible={isSettingAddOn}
        buttonSaveTitle={"Submit"}
        onPressCancel={() => {
          onCloseModal();
        }}
        onPressSave={() => handleSubmit()}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <PickerSearch
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchCodeAddOn(v.branchCode);
                  setBranchNameSelected(v);
                  setSearchBranchName("");
                } else {
                  setBranchCodeAddOn("");
                  setBranchNameSelected([]);
                  setSearchBranchName("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Branch"}
              placeholderPicker={"search branch location"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.branchNameSelected}
              helperText={errors.branchNameSelected}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <PickerSearch
              dataList={productList}
              onInputChange={(event, newInputValue) => {
                setSearchProductName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setProductCodeAddOn(v.masterProductCode);
                  setProductNameSelected(v);
                  setSearchProductName("");
                } else {
                  setProductCodeAddOn("");
                  setProductNameSelected([]);
                  setSearchProductName("");
                }
              }}
              getOptionLabel={(productList) => productList.productName || ""}
              labelPicker={"Choose Product"}
              placeholderPicker={"search product"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.productNameSelected}
              helperText={errors.productNameSelected}
            />
          </Grid>
        </Grid>
      </Modal>

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </div>
  );
}

export default AddOns;
