import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function FormDatePicker({
  labelFormDatePicker,
  dateValue,
  onChangeDate,
  formSize,
  ...props
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={labelFormDatePicker}
        value={dateValue}
        onChange={onChangeDate}
        slotProps={{
          textField: { size: formSize ? formSize : "small", fullWidth: true },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
}

export default FormDatePicker;
