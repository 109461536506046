import React, { useState, useEffect } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarFooter,
} from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Badge } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExtensionIcon from "@mui/icons-material/Extension";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";
import AllInboxRoundedIcon from "@mui/icons-material/AllInboxRounded";
import ManageHistoryRoundedIcon from "@mui/icons-material/ManageHistoryRounded";
import PollRoundedIcon from "@mui/icons-material/PollRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import FactoryIcon from "@mui/icons-material/Factory";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import LiquorRoundedIcon from "@mui/icons-material/LiquorRounded";
import LocalConvenienceStoreRoundedIcon from "@mui/icons-material/LocalConvenienceStoreRounded";
import PointOfSaleRoundedIcon from "@mui/icons-material/PointOfSaleRounded";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import ShoppingBasketRoundedIcon from "@mui/icons-material/ShoppingBasketRounded";
import Groups3RoundedIcon from "@mui/icons-material/Groups3Rounded";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import TableViewIcon from "@mui/icons-material/TableView";
import {
  ReportOffOutlined,
  ReportOffSharp,
  ReportOutlined,
} from "@mui/icons-material";
const SideBar = () => {
  const location = useLocation();
  const [isCollapsed, setisCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [menuCategories, setMenuCategories] = useState([
    {
      title: "Report Sales",
      icon: <PollRoundedIcon />,
      subItems: [
        { title: "Balance", path: "/balance", icon: <TableViewIcon /> },
        // { title: "Income", path: "/income", icon: <InputIcon /> },
        // { title: "Expenses", path: "/expenses", icon: <OutputIcon /> },
        {
          title: "Sales",
          path: "/transaction",
          icon: <BarChartOutlinedIcon />,
        },
        {
          title: "Daily Report",
          path: "/dailyProvit",
          icon: <TrendingUpIcon />,
        },
        {
          title: "Product Profit",
          path: "/productProvit",
          icon: <LocalMallIcon />,
        },
        {
          title: "Current Stock",
          path: "/currentStock",
          icon: <MoveToInboxIcon />,
        },
      ],
    },
    {
      title: "Inventory Products",
      icon: <AllInboxRoundedIcon />,
      subItems: [
        { title: "Stock Card", path: "/stockCard", icon: <WarehouseIcon /> },
        {
          title: "All Products",
          path: "/products",
          icon: <LiquorRoundedIcon />,
        },
        // { title: "Add On", path: "/addOns", icon: <ExtensionIcon /> },
        {
          title: "Purchase Order",
          path: "/purchases",
          icon: <ShoppingBasketRoundedIcon />,
        },
        {
          title: "Product Receipt",
          path: "/productReceipt",
          icon: <ReceiptIcon />,
        },
        { title: "Stock In", path: "/stockIn", icon: <MoveDownIcon /> },
        { title: "Stock Out", path: "/stockOut", icon: <MoveUpIcon /> },
        {
          title: "Transfer Stock",
          path: "/inventory",
          icon: <SwapHorizIcon />,
        },
        {
          title: "Stock Opname",
          path: "/stockOpname",
          icon: <ManageHistoryIcon />,
        },
      ],
    },
    {
      title: "Management",
      icon: <ManageHistoryRoundedIcon />,
      subItems: [
        // { title: "Brands", path: "/brands", icon: <FactoryIcon /> },
        {
          title: "Categories",
          path: "/categories",
          icon: <ClassOutlinedIcon />,
        },
        {
          title: "Suppliers",
          path: "/supplier",
          icon: <LocalShippingRoundedIcon />,
        },
        {
          title: "Outlets",
          path: "/branches",
          icon: <LocalConvenienceStoreRoundedIcon />,
        },
        { title: "Users", path: "/users", icon: <Groups3RoundedIcon /> },
      ],
    },
  ]);

  // ================
  //   <div
  //   style={{
  //     padding: "0 24px",
  //     marginBottom: "8px",
  //     marginTop: "20px",
  //   }}
  // >
  //   <Typography
  //     variant="body2"
  //     fontWeight={300}
  //     style={{
  //       opacity: isCollapsed ? 0 : 0.5,
  //       letterSpacing: "0.5px",
  //       color: "#FFFFFF",
  //     }}
  //   >
  //     Management
  //   </Typography>
  // </div>
  // <SubMenu icon={<ManageHistoryRoundedIcon />} label="Manage">
  //   <Link to={"/brands"} className="menu-bars">
  //     <MenuItem icon={<FactoryIcon />}>Brands</MenuItem>
  //   </Link>
  //   <Link to="/categories" className="menu-bars">
  //     <MenuItem icon={<ClassOutlinedIcon />}>Categories</MenuItem>
  //   </Link>
  //   <Link to="/supplier" className="menu-bars">
  //     <MenuItem icon={<LocalShippingRoundedIcon />}>
  //       Suppliers
  //     </MenuItem>
  //   </Link>
  //   <Link to="/branches" className="menu-bars">
  //     <MenuItem icon={<LocalConvenienceStoreRoundedIcon />}>
  //       Outlets
  //     </MenuItem>
  //   </Link>
  //   <Link to="/purchases" className="menu-bars">
  //     <MenuItem icon={<ShoppingBasketRoundedIcon />}>
  //       Purchase Order
  //     </MenuItem>
  //   </Link>
  //   <Link to="/users" className="menu-bars">
  //     <MenuItem icon={<Groups3RoundedIcon />}>Users</MenuItem>
  //   </Link>
  // </SubMenu>

  // useEffect(() => {
  //   alert(JSON.stringify(location));
  // }, []);

  // const handleMenuItemClick = (path) => {
  //   setActiveMenuItem(path);
  // };

  return (
    <>
      <Sidebar
        collapsed={isCollapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        backgroundColor="#233044"
        breakPoint="md"
      >
        <div>
          <Menu
            iconShape="square"
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                if (level === 0) {
                  return {
                    color: disabled ? "#FFFFFF" : "#FFFFFF",
                    backgroundColor: active ? "#1e293a" : "#233044",
                    "&:hover": {
                      backgroundColor: "#1e293a !important",
                      color: "white !important",
                      borderRadius: "8px !important",
                      fontWeight: "bold !important",
                    },
                  };
                } else {
                  return {
                    color: disabled ? "#FFFFFF" : "#FFFFFF",
                    backgroundColor: active ? "#1e293a" : "#233044",
                    "&:hover": {
                      backgroundColor: "#1e293a !important",
                      color: "white !important",
                      fontWeight: "bold !important",
                    },
                  };
                }
              },
            }}
          >
            {/* LOGO */}
            <MenuItem
              onClick={() => setisCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <Typography sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                    BOTTLE GARDEN
                  </Typography>
                  <IconButton
                    sx={{ color: "#FFFFFF" }}
                    onClick={() => setisCollapsed(!isCollapsed)}
                  >
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            <Link to="/" className="menu-bars">
              <MenuItem icon={<HomeRoundedIcon />}>Dashboard</MenuItem>
            </Link>

            {menuCategories.map((menuItem, index) => (
              <React.Fragment key={index}>
                <div
                  style={{
                    padding: "0 24px",
                    marginBottom: "8px",
                    marginTop: "20px",
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={300}
                    style={{
                      opacity: isCollapsed ? 0 : 0.5,
                      letterSpacing: "0.5px",
                      color: "#FFFFFF",
                    }}
                  >
                    {menuItem.title}
                  </Typography>
                </div>
                <SubMenu icon={menuItem.icon} label={menuItem.title}>
                  {menuItem.subItems.map((subItem) => (
                    <Link
                      to={subItem.path}
                      className="menu-bars"
                      key={subItem.title}
                    >
                      <MenuItem icon={subItem.icon}>{subItem.title}</MenuItem>
                    </Link>
                  ))}
                </SubMenu>
              </React.Fragment>
            ))}

            {/* <div
              style={{
                padding: "0 24px",
                marginBottom: "8px",
                marginTop: "20px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight={300}
                style={{
                  opacity: isCollapsed ? 0 : 0.5,
                  letterSpacing: "0.5px",
                  color: "#FFFFFF",
                }}
              >
                Report Sales
              </Typography>
            </div>
            <SubMenu icon={<PollRoundedIcon />} label="Reports">
              <Link to={"/balance"} className="menu-bars">
                <MenuItem icon={<TableViewIcon />}>Balance</MenuItem>
              </Link>
              <Link to={"/income"} className="menu-bars">
                <MenuItem icon={<InputIcon />}>Income</MenuItem>
              </Link>
              <Link to={"/expenses"} className="menu-bars">
                <MenuItem icon={<OutputIcon />}>Expenses</MenuItem>
              </Link>
              <Link to="/transaction" className="menu-bars">
                <MenuItem icon={<BarChartOutlinedIcon />}>Sales</MenuItem>
              </Link>
              <Link to="/dailyProvit" className="menu-bars">
                <MenuItem icon={<TrendingUpIcon />}>Daily Profit</MenuItem>
              </Link>
              <Link to="/productProvit" className="menu-bars">
                <MenuItem icon={<LocalMallIcon />}>Product Profit</MenuItem>
              </Link>
              <Link to="/currentStock" className="menu-bars">
                <MenuItem icon={<MoveToInboxIcon />}>Current Stock</MenuItem>
              </Link>
            </SubMenu> */}

            {/* 
            <div
              style={{
                padding: "0 24px",
                marginBottom: "8px",
                marginTop: "20px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight={300}
                style={{
                  opacity: isCollapsed ? 0 : 0.5,
                  letterSpacing: "0.5px",
                  color: "#FFFFFF",
                }}
              >
                Inventory Products
              </Typography>
            </div>
            <SubMenu icon={<AllInboxRoundedIcon />} label="Inventories">
              <Link to="/stockCard" className="menu-bars">
                <MenuItem icon={<WarehouseIcon />}>Stock Card</MenuItem>
              </Link>
              <Link to={"/products"} className="menu-bars">
                <MenuItem icon={<LiquorRoundedIcon />}>All Products</MenuItem>
              </Link>
              <Link to={"/addOns"} className="menu-bars">
                <MenuItem icon={<ExtensionIcon />}>Add Ons</MenuItem>
              </Link>
              <Link to={"/productReceipt"} className="menu-bars">
                <MenuItem icon={<ReceiptIcon />}>Product Receipt</MenuItem>
              </Link>
              <Link to={"/stockIn"} className="menu-bars">
                <MenuItem icon={<MoveDownIcon />}>Stock In</MenuItem>
              </Link>
              <Link to={"/stockOut"} className="menu-bars">
                <MenuItem icon={<MoveUpIcon />}>Stock Out</MenuItem>
              </Link>
              <Link to={"/inventory"} className="menu-bars">
                <MenuItem icon={<SwapHorizIcon />}>Transfer Stock</MenuItem>
              </Link>
              <Link to={"/stockOpname"} className="menu-bars">
                <MenuItem icon={<ManageHistoryIcon />}>Stock Opname</MenuItem>
              </Link>
            </SubMenu>

            <div
              style={{
                padding: "0 24px",
                marginBottom: "8px",
                marginTop: "20px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight={300}
                style={{
                  opacity: isCollapsed ? 0 : 0.5,
                  letterSpacing: "0.5px",
                  color: "#FFFFFF",
                }}
              >
                Management
              </Typography>
            </div>
            <SubMenu icon={<ManageHistoryRoundedIcon />} label="Manage">
              <Link to={"/brands"} className="menu-bars">
                <MenuItem icon={<FactoryIcon />}>Brands</MenuItem>
              </Link>
              <Link to="/categories" className="menu-bars">
                <MenuItem icon={<ClassOutlinedIcon />}>Categories</MenuItem>
              </Link>
              <Link to="/supplier" className="menu-bars">
                <MenuItem icon={<LocalShippingRoundedIcon />}>
                  Suppliers
                </MenuItem>
              </Link>
              <Link to="/branches" className="menu-bars">
                <MenuItem icon={<LocalConvenienceStoreRoundedIcon />}>
                  Outlets
                </MenuItem>
              </Link>
              <Link to="/purchases" className="menu-bars">
                <MenuItem icon={<ShoppingBasketRoundedIcon />}>
                  Purchase Order
                </MenuItem>
              </Link>
              <Link to="/users" className="menu-bars">
                <MenuItem icon={<Groups3RoundedIcon />}>Users</MenuItem>
              </Link>
            </SubMenu> */}
          </Menu>
        </div>
      </Sidebar>
      <main>
        <div style={{ padding: "16px 2px ", color: "#44596e" }}>
          <div style={{ marginBottom: "16px" }}>
            {broken && (
              <IconButton onClick={() => setToggled(!toggled)}>
                <MenuOutlinedIcon />
              </IconButton>
            )}
          </div>
        </div>
      </main>
    </>
  );
};
export default SideBar;
