import React, { useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "./AuthProvider";

const PrivateRoute = ({ children }) => {
  const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
  const { isLoggedIn } = useContext(AuthContext);
  const location = useLocation();

  // useEffect(() => {
  //   if (storedIsLoggedIn === null) {
  //     // Redirect if not authenticated
  //     Navigate("/login");
  //   }
  // }, []);

  if (storedIsLoggedIn === null) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;
