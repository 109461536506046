import React from "react";
import { ResponsiveContainer, Legend, Pie, Cell, PieChart } from "recharts";

const data = [
  {
    name: "Male",
    value: 145,
  },
  {
    name: "Female",
    value: 145,
  },
  {
    name: "Others",
    value: 145,
  },
];

const RADIAN = Math.pi / 180;
const COLORS = ["#00C49F", "#FFBB28", "#FF8042"];

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >{`${(percent * 100).toFixed(0)}%`}</text>
  );
};

function PieChartComponent() {
  return (
    <div className="w-full h-[22rem] bg-white p-4 rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] flex flex-col">
      <strong className="text-gray-700 font-medium">Buyer</strong>
      <div className="w-full mt-3 flex-1 text-xs">
        <ResponsiveContainer width="100%">
          <PieChart width={400} height={300}>
            <Pie
              data={data}
              cx="50%"
              cy="45%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={105}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default PieChartComponent;
