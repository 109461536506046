import React from "react";
import { ColorModeContext, useMode } from "./contexts/Theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AuthProvider } from "./contexts/AuthProvider";
import { RouterProvider } from "react-router-dom";
import { Routers } from "./routes";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { router } from "./routes";

const App = () => {
  const [theme, colorMode] = useMode();
  return (
    <AuthProvider>
      {/* <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}> */}
      {/* <LocalizationProvider dateAdapter={AdapterMoment}> */}
      <CssBaseline />
      <Routers />
      {/* </LocalizationProvider> */}
      {/* <RouterProvider router={router} /> */}
      {/* </ThemeProvider>
      </ColorModeContext.Provider> */}
    </AuthProvider>
  );
};

export default App;
