import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function CustomizedSnackbars({
  isOpen,
  typeInfo,
  messageInfo,
  handleCloseSnackbar,
}) {
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleCloseSnackbar();
  };

  return (
    <div>
      <Snackbar
        key={vertical + horizontal}
        open={isOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={handleClose}
          severity={typeInfo}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {messageInfo}
        </Alert>
      </Snackbar>
    </div>
  );
}
