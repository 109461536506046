import React, { useEffect, useState } from "react";
import api from "../../helpers/api";
import {
  TableList,
  Spinner,
  Modal,
  FormInput,
  PickerSearch,
} from "../../components";
import { Button, Grid, Typography } from "@mui/material";
import CustomizedSnackbars from "../../components/base/Snackbar";
import { tableHeadBranch, statusList } from "../../utils/Constants";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SearchOutlined } from "@mui/icons-material";

export default function Branches() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("");

  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // ===========
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  // ==========
  const [branchNameInput, setBranchNameInput] = useState("");
  const [picNameInput, setPicNameInput] = useState("");
  const [branchPhoneInput, setBranchPhoneInput] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [statusSelected, setStatusSelected] = useState([]);
  const [branchSelected, setBranchSelected] = useState([]);
  // ==========

  const onGetBranches = async () => {
    setIsLoading(true);
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setBranchList(res.data.data);
            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onAddBranch = async () => {
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .saveBranch(
          branchNameInput,
          picNameInput,
          statusSelected.label,
          statusSelected.value,
          branchPhoneInput,
          addressInput
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  const onEditBranch = async () => {
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .editBranch(
          branchSelected.id,
          branchNameInput,
          picNameInput,
          statusSelected.label,
          statusSelected.value,
          branchPhoneInput,
          addressInput
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  // ==========

  useEffect(() => {
    const fetch = async () => {
      await onGetBranches();
    };
    fetch();
  }, []);

  useEffect(() => {
    if (isReload) {
      onGetBranches();
    }
  }, [isReload]);

  // ==========

  const onSuccessSubmit = async () => {
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
    setBranchNameInput("");
    setPicNameInput("");
    setBranchPhoneInput("");
    setAddressInput("");
    setStatusSelected([]);
  };

  const onError = async (message) => {
    setIsSpinner(false);
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
    setIsReload(false);
  };

  const onModalClose = () => {
    setBranchNameInput("");
    setPicNameInput("");
    setBranchPhoneInput("");
    setAddressInput("");
    setStatusSelected([]);
    setIsOpenModal(false);
    setErrors({});
  };

  const validateForm = () => {
    const newErrors = {};
    if (!branchNameInput || branchNameInput.trim() === "") {
      newErrors.branchNameInput = "Branch Name is required";
    }
    if (!picNameInput || picNameInput.trim() === "") {
      newErrors.picNameInput = "PIC is required";
    }
    if (statusSelected.length === 0) {
      newErrors.statusSelected = "Status is required";
    }
    if (!branchPhoneInput || branchPhoneInput.trim() === "") {
      newErrors.branchPhoneInput = "Phone is required";
    }
    if (!addressInput || addressInput.trim() === "") {
      newErrors.addressInput = "Address is required";
    }
    return newErrors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    if (mode === "add") {
      await onAddBranch();
    } else if (mode === "edit") {
      await onEditBranch();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditItem = (itemSelected, indexSelected) => {
    const status = statusList.filter(
      (i) => i.value === itemSelected.statusCode
    );
    setMode("edit");
    setBranchSelected(itemSelected);
    setBranchNameInput(itemSelected.branchName);
    setPicNameInput(itemSelected.supplierContacName);
    setStatusSelected(status[0]);
    setBranchPhoneInput(itemSelected.branchPhone);
    setAddressInput(itemSelected.address);
    setIsOpenModal(true);
  };

  // ==========

  const breadcrumbs = [
    <Typography key="3" color="text.primary" fontSize={12}>
      Outlets
    </Typography>,
  ];

  // =========

  return (
    <div className="max-w-[94%] flex flex-col m-auto">
      <Spinner isShowSpinner={isSpinner} />
      <div className="flex w-full h-16 mt-6 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
        <div className="flex flex-row w-full px-6 justify-between items-center">
          <div className="">
            <h3 className="text-xl font-semibold">Outlets</h3>
          </div>
          <div className="">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Outlet List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setMode("add");
              setIsOpenModal(true);
            }}
          >
            Add New Outlet
          </Button>
        </div>

        {/* search */}
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <FormInput
              size="small"
              label="Search Outlet Name"
              name="searchBranchName"
              value={searchBranchName}
              onChange={(event) => setSearchBranchName(event.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              component="label"
              variant="contained"
              tabIndex={-1}
              startIcon={<SearchOutlined />}
              onClick={() => {
                setIsReload(true);
              }}
              sx={{
                marginTop: 1,
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* table list */}
        <TableList
          tableStructure="outlet-list"
          tableName={"Outlet List"}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadBranch}
          data={branchList}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onEditData={handleEditItem}
        />
      </div>

      <Modal
        modalTitle={`${mode === "edit" ? "Edit Outlet" : "Add New Outlet"}`}
        isVisible={isOpenModal}
        buttonSaveTitle={"Save Outlet"}
        onPressCancel={() => {
          onModalClose();
        }}
        onPressSave={handleSubmit}
      >
        <FormInput
          label="Outlet Name"
          name="branchNameInput"
          error={!!errors.branchNameInput}
          helperText={errors.branchNameInput}
          value={branchNameInput}
          onChange={(event) => {
            setBranchNameInput(event.target.value);
            setErrors({});
          }}
        />

        <FormInput
          label="PIC Name"
          name="picNameInput"
          error={!!errors.picNameInput}
          helperText={errors.picNameInput}
          value={picNameInput}
          onChange={(event) => {
            setPicNameInput(event.target.value);
            setErrors({});
          }}
        />

        <PickerSearch
          value={statusSelected}
          dataList={statusList}
          onChange={(e, v) => {
            if (v) {
              setStatusSelected(v);
            } else {
              setStatusSelected("");
            }
          }}
          getOptionLabel={(statusList) => statusList.label || ""}
          labelPicker={"Choose Status"}
          placeholderPicker={"choose status"}
          sizePicker={"large"}
          isVisibleLabelPicker={true}
          labelTitlePicker={""}
          error={!!errors.statusSelected}
          helperText={errors.statusSelected}
        />

        <FormInput
          label="Phone"
          name="branchPhoneInput"
          error={!!errors.branchPhoneInput}
          helperText={errors.branchPhoneInput}
          value={branchPhoneInput}
          onChange={(event) => {
            setBranchPhoneInput(event.target.value);
            setErrors({});
          }}
        />

        <FormInput
          label="Address"
          name="addressInput"
          error={!!errors.addressInput}
          helperText={errors.addressInput}
          value={addressInput}
          onChange={(event) => {
            setAddressInput(event.target.value);
            setErrors({});
          }}
        />
      </Modal>

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </div>
  );
}
