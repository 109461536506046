const getRandomChar = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const randomIndex = Math.floor(Math.random() * characters.length);
  return characters.charAt(randomIndex);
};

const generateCorrelationId = (length = 24) => {
  let randomString = "";
  for (let i = 0; i < length; i++) {
    randomString += getRandomChar();
  }
  return randomString;
};

export { generateCorrelationId };
