import React from "react";
import {
  createBrowserRouter,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { AppLayout, MainLayout, Layout } from "../components";
import {
  Customers,
  Dashboard,
  Employees,
  Inventories,
  Products,
  Report,
  Login,
  Brands,
  Categories,
  Supplier,
  Branches,
  Inventory,
  Transaction,
  Purchases,
  Users,
  Balance,
  Income,
  Expenses,
  CurrentStock,
  DailyProvit,
  ProductReceipt,
  StockIn,
  StockOut,
  StockOpname,
  ProductProvit,
  Warehouse,
  AddOns,
  StockCard,
} from "../pages";
import PrivateRoute from "../contexts/PrivateRoute";

export function Routers() {
  return (
    <Router>
      <Routes>
        <Route path="/Login" element={<Login />} />
        {/*  */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <MainLayout />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/products"
            element={
              <PrivateRoute>
                <Products />
              </PrivateRoute>
            }
          />
          <Route
            path="/Brands"
            element={
              <PrivateRoute>
                <Brands />
              </PrivateRoute>
            }
          />
          <Route
            path="/Categories"
            element={
              <PrivateRoute>
                <Categories />
              </PrivateRoute>
            }
          />
          <Route
            path="/Supplier"
            element={
              <PrivateRoute>
                <Supplier />
              </PrivateRoute>
            }
          />
          <Route
            path="/Inventory"
            element={
              <PrivateRoute>
                <Inventory />
              </PrivateRoute>
            }
          />
          <Route
            path="/Transaction"
            element={
              <PrivateRoute>
                <Transaction />
              </PrivateRoute>
            }
          />
          <Route
            path="/Purchases"
            element={
              <PrivateRoute>
                <Purchases />
              </PrivateRoute>
            }
          />
          <Route
            path="/Branches"
            element={
              <PrivateRoute>
                <Branches />
              </PrivateRoute>
            }
          />

          <Route
            path="/Users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />

          <Route
            path="/Balance"
            element={
              <PrivateRoute>
                <Balance />
              </PrivateRoute>
            }
          />

          <Route
            path="/Income"
            element={
              <PrivateRoute>
                <Income />
              </PrivateRoute>
            }
          />

          <Route
            path="/Expenses"
            element={
              <PrivateRoute>
                <Expenses />
              </PrivateRoute>
            }
          />

          <Route
            path="/CurrentStock"
            element={
              <PrivateRoute>
                <CurrentStock />
              </PrivateRoute>
            }
          />

          <Route
            path="/DailyProvit"
            element={
              <PrivateRoute>
                <DailyProvit />
              </PrivateRoute>
            }
          />

          <Route
            path="/ProductProvit"
            element={
              <PrivateRoute>
                <ProductProvit />
              </PrivateRoute>
            }
          />

          <Route
            path="/ProductReceipt"
            element={
              <PrivateRoute>
                <ProductReceipt />
              </PrivateRoute>
            }
          />

          <Route
            path="/StockIn"
            element={
              <PrivateRoute>
                <StockIn />
              </PrivateRoute>
            }
          />

          <Route
            path="/StockOut"
            element={
              <PrivateRoute>
                <StockOut />
              </PrivateRoute>
            }
          />

          <Route
            path="/StockOpname"
            element={
              <PrivateRoute>
                <StockOpname />
              </PrivateRoute>
            }
          />

          <Route
            path="/StockCard"
            element={
              <PrivateRoute>
                <StockCard />
              </PrivateRoute>
            }
          />

          <Route
            path="/AddOns"
            element={
              <PrivateRoute>
                <AddOns />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}
