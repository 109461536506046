import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Grid,
  Typography,
  TablePagination,
  TableFooter,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Button,
  Container,
  Skeleton,
  TextField,
  InputAdornment,
} from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import LockResetIcon from "@mui/icons-material/LockReset";
import {
  EditOffOutlined,
  EditSharp,
  MoreVertOutlined,
  SearchOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import NumberFormat from "../../helpers/functions/numberFormat";
import moment from "moment/moment";

const TableData = ({
  tableName,
  data,
  tableHead,
  isLoading,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  dataStructure,
  onPreviewDetailItem,
  onEditItem,
  onChangePassword,
}) => {
  const theme = useTheme();

  //   const formattedData = data.map((item) => {
  //     switch (dataStructure) {
  //       case "brand":
  //         return {
  //           ...item,
  //           brandDesc:
  //             item.brandDesc.length > 20
  //               ? `${item.brandDesc.substring(0, 40)}...`
  //               : item.brandDesc,
  //         };
  //       case "category":
  //         return {
  //           ...item,
  //         };
  //       default:
  //         return item;
  //     }
  //   });

  const renderStatus = (item, index) => {
    if (item.status === 0) {
      return (
        <Typography variant="body2" color="common.white">
          Not Ready Sale
        </Typography>
      );
    } else if (item.status === 301) {
      return (
        <Typography variant="body2" color="common.white">
          Not For Sale
        </Typography>
      );
    } else {
      return (
        <Typography variant="body2" color="common.white">
          Ready To Sale
        </Typography>
      );
    }
  };

  const renderDataTable = (item, index) => {
    if (dataStructure === "brand") {
      return (
        <TableRow key={item.id}>
          <TableCell>{index + 1}</TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.brandName}
            </Typography>
            {/* <Typography variant="subtitle2" color="primary">
              {item.brandDesc.length > 20
                ? `${item.brandDesc.substring(0, 40)}...`
                : item.brandDesc}
            </Typography> */}
          </TableCell>
          <TableCell>{item.createdDate}</TableCell>
          <TableCell>{item.updatedDate}</TableCell>
          <TableCell>
            <Box
              sx={{
                backgroundColor: "green",
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography variant="body2" color="common.white">
                {"Active"}
              </Typography>
            </Box>
            {/* <Box
              sx={{
                backgroundColor:
                  item.status === "Active"
                    ? "green"
                    : item.status === "Pending"
                    ? "blue"
                    : "orange",
                padding: "3px 10px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography variant="body2" color="common.white">
                {item.status}
              </Typography>
            </Box> */}
          </TableCell>
          <TableCell>
            {/* <Tooltip title="Preview Detail">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onPreviewDetailItem(item, index)}
              >
                <VisibilityOutlined />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Edit">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onEditItem(item, index)}
              >
                <EditSharp />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    } else if (dataStructure === "categories") {
      return (
        <TableRow key={item.id}>
          <TableCell>{index + 1}</TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.categoryName}
            </Typography>
          </TableCell>
          <TableCell>{"-"}</TableCell>
          <TableCell>
            <Box
              sx={{
                backgroundColor: "green",
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography variant="body2" color="common.white">
                {"Active"}
              </Typography>
            </Box>
          </TableCell>
          <TableCell>
            {/* <Tooltip title="Preview Detail">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onPreviewDetailItem(item, index)}
              >
                <VisibilityOutlined />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Edit">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onEditItem(item, index)}
              >
                <EditSharp />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    } else if (dataStructure === "supplier") {
      return (
        <TableRow key={item.id}>
          <TableCell>{index + 1}</TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.supplierName}
            </Typography>
          </TableCell>
          <TableCell>{item.supplierContacName}</TableCell>
          <TableCell>{item.supplierPhone}</TableCell>
          <TableCell>{item.supplierEmail}</TableCell>
          <TableCell>
            {/* <Tooltip title="Preview Detail">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onPreviewDetailItem(item, index)}
              >
                <VisibilityOutlined />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Edit">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onEditItem(item, index)}
              >
                <EditSharp />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    } else if (dataStructure === "branch") {
      return (
        <TableRow key={item.id}>
          <TableCell>{index + 1}</TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.branchName}
            </Typography>
          </TableCell>
          <TableCell>{item.address}</TableCell>
          <TableCell>{item.branchPhone}</TableCell>
          <TableCell>
            <Box
              sx={{
                backgroundColor: `${
                  item.statusName === "Active" ? "green" : "red"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography variant="body2" color="common.white">
                {item.statusName}
              </Typography>
            </Box>
          </TableCell>
          <TableCell>
            {/* <Tooltip title="Preview Detail">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onPreviewDetailItem(item, index)}
              >
                <VisibilityOutlined />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Edit">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onEditItem(item, index)}
              >
                <EditSharp />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    } else if (dataStructure === "products") {
      return (
        <TableRow key={item.id}>
          <TableCell>{index + 1}</TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.productName}
            </Typography>
          </TableCell>
          <TableCell>{item.categoryName}</TableCell>
          <TableCell>{item.brandName}</TableCell>
          <TableCell>
            <Box
              sx={{
                backgroundColor: `green`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography variant="body2" color="common.white">
                {"Active"}
              </Typography>
            </Box>
          </TableCell>
          <TableCell>
            <Tooltip title="Preview Detail">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onPreviewDetailItem(item, index)}
              >
                <VisibilityOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onEditItem(item, index)}
              >
                <EditSharp />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    } else if (dataStructure === "transaction") {
      return (
        <TableRow key={item.id}>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.transactionNo}
            </Typography>
          </TableCell>
          <TableCell>{item.transactionToName}</TableCell>
          <TableCell
            sx={{
              textAlign: "center",
            }}
          >{`${NumberFormat(0, item.item)} Items`}</TableCell>
          <TableCell>{`Rp. ${NumberFormat(0, item.netPayment)}`}</TableCell>
          <TableCell>
            {moment(item.transactionDate).format("DD MMM YYYY, h:mm")}
          </TableCell>
          <TableCell>
            <Box
              sx={{
                backgroundColor: `${
                  item.statusName === "Done" ? "green" : "red"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography variant="body2" color="common.white">
                {item.statusName}
              </Typography>
            </Box>
          </TableCell>
          <TableCell>
            <Tooltip title="Preview Detail">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onPreviewDetailItem(item, index)}
              >
                <VisibilityOutlined />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    } else if (dataStructure === "inventory") {
      return (
        <TableRow key={item.id}>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.branchName}
            </Typography>
          </TableCell>
          <TableCell>{item.productName}</TableCell>
          <TableCell>{`${NumberFormat(0, item.quantity)} pcs`}</TableCell>
          <TableCell>{`Rp. ${NumberFormat(0, item.trxPrice)}`}</TableCell>
          <TableCell>
            <Box
              sx={{
                backgroundColor: `${item.status === 201 ? "green" : "red"}`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              {renderStatus(item, index)}
            </Box>
          </TableCell>
          <TableCell>
            <Tooltip title="Setting Selling Price">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onEditItem(item, index)}
              >
                <EditSharp />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    } else if (dataStructure === "purchases") {
      return (
        <TableRow key={item.id}>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.transactionNo}
            </Typography>
          </TableCell>
          <TableCell>{item.transactionType}</TableCell>
          <TableCell>{`${NumberFormat(0, item.item)} Items`}</TableCell>
          <TableCell>
            <Box
              sx={{
                backgroundColor: `${
                  item.statusName === "Done" ? "green" : "red"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography variant="body2" color="common.white">
                {item.statusName}
              </Typography>
            </Box>
          </TableCell>
          <TableCell>
            <Tooltip title="Preview Detail">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onPreviewDetailItem(item, index)}
              >
                <VisibilityOutlined />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    } else if (dataStructure === "user") {
      const branchName = item.attachments[0];
      return (
        <TableRow key={item.id}>
          <TableCell>
            <Typography variant="body2" color="text">
              {index + 1}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.username}
            </Typography>
          </TableCell>
          <TableCell>{item.userEmail}</TableCell>
          <TableCell>
            {item.attachments.length !== 0
              ? branchName.branchName
              : item.branchCode}
          </TableCell>
          <TableCell>
            <Box
              sx={{
                backgroundColor: "green",
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography variant="body2" color="common.white">
                {"Active"}
              </Typography>
            </Box>
            {/* <Box
              sx={{
                backgroundColor: `${
                  item.statusName === "Active" ? "green" : "red"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography variant="body2" color="common.white">
                {item.statusName}
              </Typography>
            </Box> */}
          </TableCell>
          <TableCell>
            <Tooltip title="Create Login">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onPreviewDetailItem(item, index)}
              >
                <KeyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Change Password">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onChangePassword(item, index)}
              >
                <LockResetIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onEditItem(item, index)}
              >
                <EditSharp />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    } else if (dataStructure === "report-transaction") {
      return (
        <TableRow key={item.id}>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.transactionNo}
            </Typography>
          </TableCell>
          <TableCell>{item.transactionFromName}</TableCell>
          <TableCell>
            {moment(item.createdDate).format("DD MMM YYYY, h:mm")}
          </TableCell>
          <TableCell
            sx={{
              textAlign: "center",
            }}
          >
            {item.transactionType}
          </TableCell>
          <TableCell>{item.note}</TableCell>
          <TableCell>{`Rp. ${NumberFormat(0, item.netPayment)}`}</TableCell>
          <TableCell>
            <Tooltip title="Preview Detail">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onPreviewDetailItem(item, index)}
              >
                <VisibilityOutlined />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    } else if (dataStructure === "dailyProvit") {
      return (
        <TableRow key={item.id}>
          <TableCell>
            <Typography variant="body2" color="text">
              {index + 1}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {`Rp. ${NumberFormat(0, item.trxPrice)}`}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {`Rp. ${NumberFormat(0, item.discount)}`}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {`Rp. ${NumberFormat(0, item.baseprice)}`}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {`Rp. ${NumberFormat(0, item.netprice)}`}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {`${NumberFormat(0, item.grosprofit)}%`}
            </Typography>
          </TableCell>
          <TableCell>{item.agingDate}</TableCell>
        </TableRow>
      );
    } else if (dataStructure === "productProvit") {
      return (
        <TableRow key={item.id}>
          <TableCell>
            <Typography variant="body2" color="text">
              {index + 1}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.productName}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {`Rp. ${NumberFormat(0, item.trxPrice)}`}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {`Rp. ${NumberFormat(0, item.discount)}`}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {`Rp. ${NumberFormat(0, item.baseprice)}`}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {`Rp. ${NumberFormat(0, item.netprice)}`}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {`${NumberFormat(0, item.grosprofit)}%`}
            </Typography>
          </TableCell>
        </TableRow>
      );
    } else if (dataStructure === "addons") {
      return (
        <TableRow key={item.id}>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.addOnsType}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {item.branchCode ? item.branchCode : "-"}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="text">
              {`Rp. ${NumberFormat(0, item.price)}`}
            </Typography>
          </TableCell>
          <TableCell>
            <Box
              sx={{
                backgroundColor: `${item.isActive ? "green" : "red"}`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography variant="body2" color="common.white">
                {item.isActive ? "Active" : "Non active"}
              </Typography>
            </Box>
          </TableCell>
          <TableCell>
            <Tooltip title="Setting AddOn">
              <IconButton
                sx={{ "&:hover": { color: "primary.main" } }}
                onClick={() => onEditItem(item, index)}
              >
                <EditSharp />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        marginTop: "20px",
        marginBottom: "30px",
      }}
    >
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHead.map((item) => {
                return (
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.getContrastText(
                        theme.palette.primary.main
                      ),
                    }}
                  >
                    {item.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              Array(tableHead.length * (page + 1))
                .fill("")
                .map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={40} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" width={150} height={40} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" width={200} height={40} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" width={120} height={40} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={80} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="circular" width={40} height={40} />
                    </TableCell>
                  </TableRow>
                ))
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={tableHead.length}
                  align="center"
                  sx={{ py: 4 }}
                >
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <CloudUploadIcon color="secondary" fontSize="large" />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary" }}
                      >
                        {` No ${tableName} found. You can add a new ${tableName} using the
                      button above.`}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => renderDataTable(item, index))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableData;
