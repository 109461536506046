// validationHelper.js
import * as Yup from "yup";

export const validateFormInput = (username, password, brandNameInput) => {
  const schema = Yup.object({
    username: Yup.string()
      .email("Invalid username format")
      .required("Username is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  return schema.validate({ username, password });
};

export const validateFormInputBrand = (brandNameInput) => {
  const schema = Yup.object({
    brandNameInput: Yup.string().required("Brand name is required"),
  });

  return schema.validate({ brandNameInput });
};

export const validateFormInputCategory = (
  categoryNameInput
) => {
  const schema = Yup.object({
    categoryNameInput: Yup.string().required("Category name is required"),
  });

  return schema.validate({ categoryNameInput});
};

export const validateFormInputProduct = (
  productNameInput,
  categoryNameSelected
) => {
  const schema = Yup.object({
    productNameInput: Yup.string().required("Product name is required"),
    categoryNameSelected: Yup.object().required(
      "Category is required to choose"
    ),
  });

  return schema.validate({
    productNameInput,
    categoryNameSelected,
  });
};

export const validateFormInputAddOn = (addOnName, addOnPrice, addOnType) => {
  const schema = Yup.object({
    addOnName: Yup.string().required("Adds on name is required"),
    addOnPrice: Yup.number().required("Adds on price is required"),
    addOnType: Yup.string().required("Adds on type is required"),
  });

  return schema.validate({
    addOnName,
    addOnPrice,
    addOnType,
  });
};

export const validateFormInputSettingAddon = (
  branchNameSelected,
  productNameSelected
) => {
  const schema = Yup.object({
    branchNameSelected: Yup.object().required("Branch is required to choose"),
    productNameSelected: Yup.object().required("Product is required to choose"),
  });

  return schema.validate({
    branchNameSelected,
    productNameSelected,
  });
};

export const validateFormInputSellingPrice = (
  sellingPriceInput,
  statusSelected
) => {
  const schema = Yup.object({
    sellingPriceInput: Yup.number().required("Selling price is required"),
    statusSelected: Yup.object().required("Status is required to choose"),
  });

  return schema.validate({
    sellingPriceInput,
    statusSelected,
  });
};

export const validateFormInputSupplier = (
  supplierNameInput,
  supplierContacNameInput,
  supplierPhoneInput,
  supplierEmailInput,
  supplierAddressInput
) => {
  const schema = Yup.object({
    supplierNameInput: Yup.string().required("Supplier name is required"),
    supplierContacNameInput: Yup.string().required(
      "Supplier Contact is required"
    ),
    supplierPhoneInput: Yup.string().required("Supplier phone is required"),
    supplierEmailInput: Yup.string().required("Supplier email is required"),
    supplierAddressInput: Yup.string().required("Supplier address is required"),
  });

  return schema.validate({
    supplierNameInput,
    supplierContacNameInput,
    supplierPhoneInput,
    supplierEmailInput,
    supplierAddressInput,
  });
};

export const validateFormInputBranch = (
  branchNameInput,
  picNameInput,
  statusSelected,
  branchPhoneInput,
  addressInput
) => {
  const schema = Yup.object({
    branchNameInput: Yup.string().required("Branch name is required"),
    picNameInput: Yup.string().required("PIC is required"),
    statusSelected: Yup.object().required("Status is required"),
    branchPhoneInput: Yup.string().required("Phone is required"),
    addressInput: Yup.string().required("Address is required"),
  });

  return schema.validate({
    branchNameInput,
    picNameInput,
    statusSelected,
    branchPhoneInput,
    addressInput,
  });
};

export const validateFormInputUser = (employeeName, usernameInput) => {
  const schema = Yup.object({
    employeeName: Yup.string().required("Employee name is required"),
    usernameInput: Yup.string().required("Username is required"),
  });

  return schema.validate({
    employeeName,
    usernameInput,
  });
};
