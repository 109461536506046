import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { BsFillTrash3Fill } from "react-icons/bs";
import InputAdornment from "@mui/material/InputAdornment";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
  Modal,
  FormInput,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import { tableHeadProduct, tableHeadVariant } from "../../utils/Constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import FileUpload from "../../components/base/FileUpload";
import ModalDetail from "../../components/ModalDetail";
import { CloudCircleOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import * as XLSX from "xlsx";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function Products() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isReload, setIsReload] = useState(false);
  // ==================
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("");
  // ==================
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  // ==================
  const [productList, setProductList] = useState(null);
  const [searchProductName, setSearchProductName] = useState("");
  // ==================
  const [productNameInput, setProductNameInput] = useState("");
  const [productSKU, setProductSKU] = useState("");
  const [brandName, setBrandName] = useState("");
  const [note, setNote] = useState("");
  const [files, setFiles] = useState([]);
  // ==================
  const [categoryList, setCategoryList] = useState([]);
  const [categoryNameSelected, setCategoryNameSelected] = useState([]);
  const [searchCategoryName, setSearchCategoryName] = useState("");
  // ==================
  const [brandNameSelected, setBrandNameSelected] = useState("");
  const [searchBrandName, setSearchBrandName] = useState("");
  // =================
  const [productSelected, setProductSelected] = useState([]);
  const [isVisibleDetail, setIsVisibleDetail] = useState(false);
  const [detailProduct, setDetailProduct] = useState([]);
  // =================
  const [variantList, setVariantList] = useState([]);

  // ===============
  const onGetProducts = async () => {
    setIsLoading(true);
    const currentPage = page + 1;
    try {
      const res = await api
        .getProduct(searchProductName, "", rowsPerPage, currentPage)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setTotalRecord(res.data.totalPage * res.data.limit);
            setProductList(res.data.data);
            setIsLoading(false);
            setIsReload(false);
          } else {
            setProductList([]);
            onError(res.message);
          }
        })
        .catch((error) => {
          setProductList([]);
          onError(error.message);
        });
    } catch (error) {
      setProductList([]);
      onError(error.message);
    }
  };

  const onGetCategories = async () => {
    try {
      const res = await api
        .getCategories(searchCategoryName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setCategoryList(res.data.data);
          } else {
            setCategoryList([]);
            onError(res.message);
          }
        })
        .catch((error) => {
          setCategoryList([]);
          onError(error.message);
        });
    } catch (error) {
      setCategoryList([]);
      onError(error.message);
    }
  };

  // const onAddProduct = async () => {
  //   setIsOpenModal(false);
  //   setIsSpinner(true);
  //   let body = new FormData();
  //   body.append("brandId", "");
  //   body.append("categoryId", categoryNameSelected.id);
  //   body.append("productName", productNameInput);
  //   body.append("categoryName", categoryNameSelected.categoryName);
  //   body.append("brandName", "");
  //   body.append("branchCode", 1);
  //   body.append("productSku", productSKU);
  //   files.forEach((file) => {
  //     body.append("file", file);
  //   });
  //   try {
  //     const res = await api
  //       .saveProduct(body, {
  //         headers: { "Content-Type": "multipart/form-data" },
  //       })
  //       .then(async (res) => {
  //         if (res.statusCode === 200) {
  //           setIsSpinner(false);
  //           onSuccessSubmit();
  //         } else {
  //           setIsOpenModal(true);
  //           onError(res.message);
  //         }
  //       })
  //       .catch((error) => {
  //         setIsOpenModal(true);
  //         onError(error.message);
  //       });
  //   } catch (error) {
  //     setIsOpenModal(true);
  //     onError(error.message);
  //   }
  // };

  const onAddProduct = async () => {
    setIsOpenModal(false);
    setIsSpinner(true);
    let body = {
      categoryId: categoryNameSelected.id,
      productName: productNameInput,
      categoryName: categoryNameSelected.categoryName,
      brandName: brandName,
      note: note,
      attachment: variantList,
    };
    console.log("add product data", body);
    try {
      const res = await api
        .saveProduct(
          categoryNameSelected.id,
          productNameInput,
          categoryNameSelected.categoryName,
          brandName,
          note,
          variantList
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  const onEditAddedVariant = async (newProductAdded) => {
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .saveProductNewOnEdit(
          productSelected.masterProductCode,
          newProductAdded
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            const newProductDetail = variantList.filter(
              (x) => x.productCode != ""
            );
            const newProductListAdded = await newProductDetail.concat(res.data);
            onEditProduct(newProductListAdded);
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  const onEditProduct = async (dataList) => {
    const body = {
      productId: productSelected.id,
      productName: productNameInput,
      brandName: null,
      brandId: null,
      categoryId: categoryNameSelected.id,
      categoryName: categoryNameSelected.categoryName,
      attachment: dataList,
    };

    console.log("body update product & variant", body);
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .editProduct(
          productSelected.id,
          productNameInput,
          null,
          null,
          categoryNameSelected.id,
          categoryNameSelected.categoryName,
          dataList
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  const onGetDetailProduct = async (data, flag) => {
    setIsSpinner(true);
    try {
      const res = await api
        .getDetailProduct(data.masterProductCode)
        .then(async (res) => {
          if (res.statusCode === 200) {
            if (flag === "Edit Product") {
              const categorySelected = [
                {
                  id: data.categoryId,
                  categoryName: data.categoryName,
                },
              ];
              setIsSpinner(false);
              setMode("Edit Product");
              setProductSelected(data);
              setVariantList(res.data.attachments);
              setProductNameInput(data.productName);
              setProductSKU(data.productSku);
              setCategoryNameSelected(categorySelected[0]);
              setIsOpenModal(true);
            } else {
              setIsSpinner(false);
              setDetailProduct(res.data);
              setIsVisibleDetail(true);
            }
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  // ==============
  useEffect(() => {
    const fetch = async () => {
      await onGetProducts();
    };
    fetch();
  }, [page]);

  useEffect(() => {
    if (isReload) {
      onGetProducts();
    }
  }, [isReload]);

  useEffect(() => {
    onGetCategories();
  }, [searchCategoryName]);

  const onError = async (message) => {
    setIsReload(false);
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const onSuccessSubmit = async () => {
    setProductNameInput("");
    setProductSKU("");
    setBrandName("");
    setNote("");
    setCategoryNameSelected([]);
    setSearchCategoryName("");
    setFiles([]);
    setVariantList([]);
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
  };

  const onCloseModal = async () => {
    setMode("");
    setBrandName("");
    setNote("");
    setProductNameInput("");
    setCategoryNameSelected([]);
    setSearchCategoryName("");
    setProductSKU("");
    setFiles([]);
    setVariantList([]);
    setErrors({});
    setIsOpenModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleUpload = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const handleUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        console.log(json);
        setVariantList(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!productNameInput || productNameInput.trim() === "") {
      newErrors.productNameInput = "Product name is required";
    }

    if (
      !categoryNameSelected ||
      categoryNameSelected === null ||
      categoryNameSelected.length === 0
    ) {
      newErrors.categoryNameSelected = "Category is required to choose";
    }

    return newErrors;
  };

  const validateFormVariant = () => {
    const hasEmptyFields = variantList.some(
      (variant) => !variant.variantName || !variant.defaultTrxPrice
    );

    if (variantList.length === 0) {
      onError("Variant must be added first...");
      return false;
    }

    if (hasEmptyFields) {
      onError(
        "Please ensure all variants have both Variant Name, Product Code, Product SKU & Basic Price filled."
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const isValid = validateFormVariant();
    if (isValid && mode === "Add New Product") {
      await onAddProduct();
    } else if (isValid && mode === "Edit Product") {
      const newProductAdded = variantList.filter((x) => x.productCode === "");
      if (newProductAdded.length !== 0) {
        await onEditAddedVariant(newProductAdded);
      } else {
        await onEditProduct(variantList);
      }
    }
  };

  const handleEditProduct = (itemSelected, indexSelected) => {
    onGetDetailProduct(itemSelected, "Edit Product");
  };

  const handlePreviewDetail = (itemSelected, indexSelected) => {
    onGetDetailProduct(itemSelected, "Detail Product");
  };

  const onAddVariantList = () => {
    setVariantList([
      ...variantList,
      {
        productCode: "",
        variantName: "",
        productSku: "",
        defaultTrxPrice: "",
        isCanDelete: true,
      },
    ]);
  };

  const handleInputChange = (event, index) => {
    const newVariants = [...variantList];
    newVariants[index][event.target.name] = event.target.value;
    setVariantList(newVariants);
  };

  const onHandleRemoveVariant = (index) => {
    const newArray = [...variantList];
    newArray.splice(index, 1);
    setVariantList(newArray);
  };

  // ===============
  const breadcrumbs = [
    <Typography key="3" color="text.primary" fontSize={12}>
      Products
    </Typography>,
  ];

  return (
    <div className="max-w-[94%] flex flex-col m-auto">
      <Spinner isShowSpinner={isSpinner} />
      <div className="flex w-full h-16 mt-6 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
        <div className="flex flex-row w-full px-6 justify-between items-center">
          <div className="">
            <h3 className="text-xl font-semibold">Product List</h3>
          </div>
          <div className="">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Product List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setMode("Add New Product");
              setIsOpenModal(true);
            }}
          >
            Add New Product
          </Button>
        </div>

        {/* search */}
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <FormInput
              size="small"
              label="Search Product"
              name="searchProductName"
              value={searchProductName}
              onChange={(event) => setSearchProductName(event.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              component="label"
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                onGetProducts();
              }}
              sx={{ marginTop: 1 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* table list */}
        <TableList
          tableStructure="product-list"
          tableName={"Product List"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadProduct}
          data={productList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={handlePreviewDetail}
          onEditData={handleEditProduct}
        />
      </div>

      <Modal
        modalTitle={mode}
        fullScreen={true}
        isVisible={isOpenModal}
        buttonSaveTitle={"Save Product"}
        onPressCancel={() => {
          onCloseModal();
        }}
        onPressSave={() => handleSubmit()}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormInput
              label="Product Name"
              name="productNameInput"
              disabled={mode === "Add Variant" ? true : false}
              error={!!errors.productNameInput}
              helperText={errors.productNameInput}
              value={productNameInput}
              onChange={(event) => {
                setProductNameInput(event.target.value);
                setErrors({});
              }}
            />
          </Grid>

          {/* {mode == "Add New Product" ? (
            <Grid item xs={12} sm={12}>
              <FileUpload onUpload={handleUpload} initialFiles={files} />
            </Grid>
          ) : null} */}
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <PickerSearch
              disabled={false}
              value={categoryNameSelected}
              dataList={categoryList}
              onInputChange={(event, newInputValue) => {
                setSearchCategoryName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setCategoryNameSelected(v);
                } else {
                  setCategoryNameSelected("");
                }
              }}
              getOptionLabel={(categoryList) => categoryList.categoryName || ""}
              labelPicker={"Choose Category"}
              placeholderPicker={"search category"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.categoryNameSelected}
              helperText={errors.categoryNameSelected}
              sx={{ marginTop: 2 }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormInput
              label="Note"
              name="note"
              multiline
              rows={3}
              error={!!errors.note}
              helperText={errors.note}
              value={note}
              onChange={(event) => {
                setNote(event.target.value);
                setErrors({});
              }}
              sx={{ marginTop: 2 }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Button
              sx={{
                marginTop: "15px",
                marginBottom: "15px",
                marginRight: "10px",
              }}
              variant="outlined"
              onClick={() => onAddVariantList()}
              startIcon={<AddCircleIcon />}
            >
              Add Variant
            </Button>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudCircleOutlined />}
            >
              Import Variant Product
              <VisuallyHiddenInput
                type="file"
                onChange={handleUploadFile}
                multiple
              />
            </Button>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TableContainer mt={1}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHeadVariant.map((item) => {
                      return (
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#060270",
                            color: "white",
                          }}
                        >
                          {item.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {variantList.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={tableHeadVariant.length}
                        align="center"
                        sx={{ py: 4 }}
                      >
                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <AddCircleIcon color="secondary" fontSize="large" />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              sx={{ color: "text.secondary" }}
                            >
                              {` No variant found. You can add a new variant using the
                      button above.`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {variantList.map((item, index) => {
                        return (
                          <TableRow key={item.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <FormInput
                                label="Variant Name"
                                name="variantName"
                                size="small"
                                error={!!errors.variantName}
                                helperText={errors.variantName}
                                value={item.variantName}
                                onChange={(event) => {
                                  handleInputChange(event, index);
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <FormInput
                                label="Product SKU"
                                name="productSku"
                                size="small"
                                error={!!errors.productSku}
                                helperText={errors.productSku}
                                value={item.productSku}
                                onChange={(event) => {
                                  handleInputChange(event, index);
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <FormInput
                                label="Basic Price"
                                name="defaultTrxPrice"
                                size="small"
                                type="number"
                                error={!!errors.defaultTrxPrice}
                                helperText={errors.defaultTrxPrice}
                                value={item.defaultTrxPrice}
                                onChange={(event) => {
                                  handleInputChange(event, index);
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      Rp.
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </TableCell>
                            {item.isCanDelete ? (
                              <TableCell>
                                <div className="flex flex-row gap-2 items-center justify-center">
                                  <Tooltip title="Delete Variant">
                                    <IconButton
                                      onClick={() =>
                                        onHandleRemoveVariant(index)
                                      }
                                      size="medium"
                                      sx={{
                                        width: 30,
                                        height: 30,
                                        borderRadius: 1,
                                        backgroundColor: "#f4e6fb",
                                        color: "#FE3133",
                                      }}
                                    >
                                      <BsFillTrash3Fill />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </TableCell>
                            ) : null}
                          </TableRow>
                        );
                      })}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Modal>

      <ModalDetail
        type={"product-detail"}
        fullScreen={true}
        onClose={() => setIsVisibleDetail(false)}
        isVisibleDetail={isVisibleDetail}
        detailProduct={detailProduct}
      />

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </div>
  );
}

export default Products;
