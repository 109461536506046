import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

function PickerSearch({
  dataList,
  onInputChange,
  onChange,
  getOptionLabel,
  labelPicker,
  placeholderPicker,
  sizePicker,
  isVisibleLabelPicker,
  labelTitlePicker,
  error,
  helperText,
  value,
  disabled,
  onOpen,
  getOptionDisabled,
  ...props
}) {
  return (
    <>
      {isVisibleLabelPicker ? (
        <h4 className="font-semibold mb-2 text-xs">{labelTitlePicker}</h4>
      ) : null}
      <Autocomplete
        disabled={disabled}
        value={value}
        options={dataList}
        onInputChange={onInputChange}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        onOpen={onOpen}
        getOptionDisabled={getOptionDisabled}
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: "flip",
                enabled: false,
              },
              {
                name: "preventOverflow",
                enabled: false,
              },
            ],
          },
          paper: {
            style: {
              maxHeight: "150px",
              overflowY: "auto",
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size={sizePicker}
            label={labelPicker}
            placeholder={placeholderPicker}
            error={error}
            helperText={helperText}
          />
        )}
        {...props}
      />
    </>
  );
}

export default PickerSearch;
