import react, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, Button } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = async () => {
    localStorage.removeItem("isLoggedIn");
    navigate("/");
  };

  return (
    <Box
      display="flex"
      backgroundColor="#FFFFFF"
      justifyContent="flex-end"
      p={2}
    >
      {/* search  */}
      {/* <Box display="flex" borderRadius="3px" backgroundColor="#F5EFE7">
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box> */}

      {/* icons */}
      <Box display="flex">
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton> */}
        {/* <IconButton>
          <PersonOutlinedIcon onClick={handleMenu} />
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => onLogout()}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </IconButton> */}

        <Button onClick={handleMenu}>
          <img
            src={
              "https://th.bing.com/th/id/OIP.3D--9WoJ9T8X_GcV_WY7UgHaHa?rs=1&pid=ImgDetMain"
            }
            className="w-10 rounded-full px-3"
          />
          <span className="text-[#1a1a1a] font-semibold text-xs">
            {"Admin"}
          </span>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => onLogout()}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Button>
      </Box>
    </Box>
  );
};

export default Navbar;
