import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import NumberFormat from "./numberFormat";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 20,
  },
  section: {
    marginVertical: 10,
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  column: {
    flex: 1,
    marginRight: 5,
  },
  label: {
    fontWeight: "bold",
    fontSize: 10,
    marginBottom: 3,
  },
  text: {
    fontSize: 9,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 6,
    marginBottom: 5,
  },
  tableHeader: {
    margin: "auto",
    marginTop: 5,
    fontSize: 8,
    marginBottom: 5,
  },
  amount: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 10,
  },
  headerWithBorder: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    paddingBottom: 5,
  },
});

const TransactionPDF = ({ transactionSelected, detailTransaction }) => (
  <Document>
    <Page
      size="A4"
      style={styles.page}
      renderFooter={(pageNumber, totalPages) => (
        <Text style={styles.pageNumber}>
          {pageNumber} / {totalPages}
        </Text>
      )}
    >
      <View style={styles.section}>
        <Text style={styles.header}>Detail Transaction</Text>

        <Text style={styles.label}>Amount</Text>
        <Text style={styles.amount}>
          {" "}
          {`Rp. ${NumberFormat(0, detailTransaction?.netPayment)}`}
        </Text>

        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.label}>Transaction No:</Text>
            <Text style={styles.text}>{detailTransaction?.transactionNo}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.label}>Transaction Date:</Text>
            <Text style={styles.text}>
              {moment(detailTransaction?.transactionDate).format(
                "DD MMM YYYY, h:mm"
              )}
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.label}>Transaction Type:</Text>
            <Text style={styles.text}>
              {detailTransaction?.transactionType}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.header}>Transaction Detail</Text>
        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.label}>Transaction From:</Text>
            <Text style={styles.text}>
              {detailTransaction?.transactionFromName}
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.label}>Transaction To:</Text>
            <Text style={styles.text}>
              {detailTransaction?.transactionToName}
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.label}>Items:</Text>
            <Text style={styles.text}>
              {" "}
              {`${NumberFormat(0, detailTransaction?.item)} Items`}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.headerWithBorder}>Items</Text>
        {detailTransaction?.attachments && (
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableHeader}>No</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableHeader}>Product Name</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableHeader}>Product Variant</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableHeader}>Brand</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableHeader}>Category</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableHeader}>Quantity</Text>
              </View>
            </View>
            {detailTransaction?.attachments.map((itemData, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{index + 1}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{itemData.productName}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{itemData.variantName}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{itemData.brandName}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{itemData.categoryName}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{`${NumberFormat(
                    0,
                    itemData.quantity
                  )} pcs`}</Text>
                </View>
              </View>
            ))}
          </View>
        )}
      </View>
    </Page>
  </Document>
);

export default TransactionPDF;
