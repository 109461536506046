// import * as XLSX from 'xlsx';

// export const exportToXLSX = (data, fileName) => {
//     const formattedData = data.map((item, index) => {
//         const formattedItem = {
//             No: index + 1, // Add 'No' field with auto-incremented number
//             ...item,
//         };
//         delete formattedItem.id; // remove id from response
//         return formattedItem;
//     });

//     const worksheet = XLSX.utils.json_to_sheet(formattedData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
//     XLSX.writeFile(workbook, `${fileName}.xlsx`);
// };


import * as XLSX from 'xlsx';

export const exportToXLSX = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName + '.xlsx');
};
