import React from "react";

const NumberFormat = (number, data) => {
  if (data !== undefined) {
    let parsed = parseFloat(data);
    // let dataIsNan = isNaN(data) ? 0 : data;

    return isNaN(parsed)
      ? 0
      : parsed
          .toFixed(number)
          .replace(".", ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return 0;
  }
};

export default NumberFormat;
