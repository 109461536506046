import React from "react";
import {
  IconButton,
  Button,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function Modal({
  isVisible,
  modalTitle,
  onPressCancel,
  onPressSave,
  buttonSaveTitle,
  children,
  fullScreen,
}) {
  const [scroll, setScroll] = React.useState("paper");
  return (
    <Dialog
      fullScreen={fullScreen ? true : false}
      scroll={scroll}
      fullWidth
      maxWidth="md"
      open={isVisible}
      onClose={() => console.log("disabled")}
    >
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>{children}</DialogContent>
      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button onClick={onPressCancel}>Cancel</Button>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          onClick={onPressSave}
        >
          {buttonSaveTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal;

// function Modal({ visible, onClose, children, modalLabel, widthSize }) {
//   if (!visible) return null;

//   const handleClose = (e) => {
//     if (e.target.id === "modalWrapper") onClose();
//   };

//   return (
//     <div
//       id="modalWrapper"
//       onClick={handleClose}
//       className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center"
//     >
//       <div
//         className={`${
//           widthSize ? widthSize : "w-[600px]"
//         } flex flex-col overflow-hidden`}
//       >
//         <div className="bg-white max-h-[550px] overflow-y-auto p-2 rounded">
//           <div className="pt-6 px-6 lg:px-8 text-left">
//             <h3 className="mb-4 text-xl font-medium text-gray-900">
//               {modalLabel}
//             </h3>
//           </div>
//           {children}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Modal;
