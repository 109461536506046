import React from "react";
import { FaUserCircle } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import { HiDotsVertical } from "react-icons/hi";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import Skeleton from "@mui/material/Skeleton";

function DashboardCard({
  fromColor,
  toColor,
  isLoadingShimmer,
  titleCard,
  dataCard,
}) {
  return (
    <div
      className={`w-full h-40 bg-gradient-to-r ${fromColor} ${toColor} rounded-xl overflow-hidden p-5 relative shadow-[0_8px_30px_rgb(0,0,0,0.12)]`}
    >
      <span className="absolute bottom-4">
        <TrendingUpIcon
          sx={{
            fontSize: 115,
            color: "white",
            opacity: 0.1,
          }}
        />
      </span>
      <div className="flex w-full h-full flex-col justify-between">
        <div className="flex w-full flex-row justify-around">
          <div className="w-full">
            {isLoadingShimmer ? (
              <Skeleton
                animation="wave"
                height={20}
                width="80%"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <h4 className="text-white font-bold text-lg w-full">
                {titleCard}
              </h4>
            )}

            {isLoadingShimmer ? (
              <Skeleton
                animation="wave"
                height={20}
                width="50%"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <span className="text-white font-bold w-full text-2xl">
                {dataCard}
              </span>
            )}
          </div>
          <div className="flex justify-center items-center ml-auto w-14">
            <div className="bg-black opacity-10 rounded-lg p-2 w-10 h-10 flex justify-center items-center">
              <FaUserCircle className="text-white text-2xl opacity-80" />
            </div>
          </div>
        </div>
        <div className="flex items-center flex-row justify-between">
          {isLoadingShimmer ? (
            <Skeleton
              animation="wave"
              height={20}
              width="100%"
              style={{ marginBottom: 6 }}
            />
          ) : (
            <h6 className="text-white font-semibold font-sans mb-0 mt-0">
              Last Month
            </h6>
          )}
          <IconButton size="small">
            <HiDotsVertical className="w-5 h-5 text-white" />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard;
