import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import StorageIcon from "@mui/icons-material/Storage";

function EmptyTableList({ colSpan, tableName }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1866ee",
      color: theme.palette.common.white,
      fontSize: 13,
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#fafafa",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <StyledTableRow>
      <StyledTableCell colSpan={colSpan} align="center" sx={{ py: 4 }}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            <StorageIcon color="primary" fontSize="medium" />
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              sx={{ color: "text.secondary", fontWeight: "bold" }}
            >
              {` No ${tableName} found.`}
            </Typography>
          </Grid>
        </Grid>
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default EmptyTableList;
